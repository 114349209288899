import { updateNotificationStatus } from 'store/monitor/notificationsSlice';

const handleNotificationAction = async ({
  dispatch,
  navigate,
  notificationId = null,
  notificationIds = [],
  links = {},
  seen = true,
  toggleReadStatus = false,
  isRead = false,
}) => {
  let status = seen;
  if (toggleReadStatus) {
    status = !isRead;
  }

  const ids = notificationId ? [notificationId] : notificationIds;

  await dispatch(
    updateNotificationStatus({
      notificationIds: ids,
      seen: status,
    })
  );

  if (navigate && (links.providedLink || links.generatedLink)) {
    navigate(links.providedLink || links.generatedLink);
  }
};

export default handleNotificationAction;
