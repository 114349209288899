import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t, msg } from '@lingui/macro';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { fnsLocales } from 'actions/locale';
import { firstPublicationDateSelector } from 'selectors/campaign';
import {
  customPeriodDatesSelector,
  periodFilterSelector,
  setCustomDate,
  setPeriod,
} from 'store/monitor/monitorSearchSlice';

import {
  ButtonSecondaryAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import {
  CustomDayPickerCaption,
  StyledDayPicker,
} from 'components/ui/inputs/DaySelect';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import { dayFormatter, getTimezoneUnawareDateString } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

export const DATE_PERIODS = [
  { textId: msg({ id: "30-days" }), value: '30D' },
  { textId: msg({ id: "90-days" }), value: '90D' },
  { textId: msg({ id: "360-days" }), value: '360D' },
  { textId: msg({ id: "custom-period" }), value: 'custom' },
];
const TODAY = new Date();

const DatePeriodButton = styled.a`
  display: block;
  padding: ${svars.spaceNormalLarge} ${svars.spaceMedium};
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  border: 1px solid ${svars.borderColorLight};
  border-radius: 0;
  &:first-child {
    border-radius: ${svars.borderRadius} ${svars.borderRadius} 0 0;
  }
  &:last-child {
    border-bottom: none;
  }
  & i {
    color: ${({ selected }) => (selected ? svars.accentColor : 'inherit')};
  }
  overflow: hidden;
  ${svars.hoverClickableCss}
  ${svars.activeClickableCss}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const HighlightedText = styled.span`
  color: ${svars.accentColor};
`;

function PeriodCaption({ customDates }) {
  return customDates?.minDate ? (
    <>
      {dayFormatter(customDates?.minDate)} &#8658;{'  '}
      {customDates?.maxDate ? (
        dayFormatter(customDates?.maxDate)
      ) : (
        <HighlightedText>... </HighlightedText>
      )}
    </>
  ) : (
    <HighlightedText>... </HighlightedText>
  );
}

PeriodCaption.propTypes = {
  customDates: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
};
PeriodCaption.defaultProps = {
  customDates: null,
};

function MonitorPeriodDropdown({
  disabled,
  currentPeriod,
  currentCustomDates,
  onValidatePeriod,
}) {
  const { campaignId } = useParams();
  const [selectedPeriod, setSelectedPeriod] = useState(currentPeriod);
  const [displayDateSelect, setDisplayDateSelect] = useState(false);
  const [customDates, setCustomDates] = useState({
    minDate: currentCustomDates.minDate
      ? parseISO(currentCustomDates.minDate)
      : null,
    maxDate: currentCustomDates.maxDate
      ? parseISO(currentCustomDates.maxDate)
      : null,
  });

  const firstPublicationDate = useSelector(
    firstPublicationDateSelector(campaignId)
  );

  const toggleDisplayDateSelect = useCallback(() => {
    if (displayDateSelect && customDates?.minDate) {
      // If open and custom dates are set, reset them
      if (customDates?.maxDate) {
        setCustomDates({ maxDate: null, minDate: customDates?.minDate });
      } else if (customDates?.minDate) {
        setCustomDates({ maxDate: customDates?.maxDate, minDate: null });
      }
    } else {
      setDisplayDateSelect(!displayDateSelect);
    }
  }, [displayDateSelect, customDates]);
  const onValidate = useCallback(() => {
    onValidatePeriod(selectedPeriod, customDates);
  }, [selectedPeriod, customDates]);
  const onUpdateCustomDates = useCallback(({ from, to }) => {
    setCustomDates({ minDate: from, maxDate: to });
  }, []);

  const isCustom = selectedPeriod === 'custom';
  const valueError =
    customDates?.minDate &&
    customDates?.maxDate &&
    isBefore(customDates?.maxDate, customDates?.minDate);
  const isValidationDisabled =
    (selectedPeriod === 'custom' &&
      (!(customDates?.minDate || customDates?.maxDate) ||
        (currentCustomDates.minDate &&
          customDates?.minDate &&
          isSameDay(
            parseISO(currentCustomDates.minDate),
            customDates?.minDate
          ) &&
          currentCustomDates.maxDate &&
          customDates?.maxDate &&
          isSameDay(
            parseISO(currentCustomDates.maxDate),
            customDates?.maxDate
          )) ||
        valueError)) ||
    (selectedPeriod !== 'custom' && selectedPeriod === currentPeriod);

  const fromDate =
    (firstPublicationDate && new Date(firstPublicationDate)) || TODAY;

  return (
    <Container>
      <span style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '30%',
            justifyContent: 'space-around',
            borderBottom: svars.lightBorderStyle,
          }}
        >
          {DATE_PERIODS.map(({ value, textId }) => (
            <DatePeriodButton
              disabled={disabled}
              key={`agrds-${textId}`}
              onClick={() => setSelectedPeriod(value)}
              selected={selectedPeriod === value}
            >
              <Trans render={capitalizedTranslation} id={textId} />
              {selectedPeriod === value ? <Icon name="check" /> : null}
            </DatePeriodButton>
          ))}
        </span>
      </span>
      {isCustom ? (
        <ButtonTransparentAccent
          icon={
            customDates?.minDate ? (
              <Icon name="delete" color="blue" />
            ) : (
              'calendar'
            )
          }
          labelPosition="right"
          style={{ backgroundColor: svars.accentColorLightest }}
          content={<PeriodCaption customDates={customDates} />}
          onClick={toggleDisplayDateSelect}
        />
      ) : null}
      {(isCustom && displayDateSelect && (
        <StyledDayPicker
          id="test"
          mode="range"
          locale={fnsLocales[window.__locale__]}
          components={{
            Caption: CustomDayPickerCaption,
          }}
          error={valueError}
          cellSize="40px"
          selected={{ from: customDates?.minDate, to: customDates?.maxDate }}
          onSelect={onUpdateCustomDates}
          fromDate={fromDate}
          toDate={TODAY}
          placeholder={t({ id: "select-max-date" })}
        />
      )) ||
        null}
      <ButtonLineLayout>
        <ButtonSecondaryAccent
          disabled={isValidationDisabled || disabled}
          onClick={onValidate}
          content={t({ id: "apply" })}
          style={{
            margin: svars.spaceNormalLarge,
          }}
        />
      </ButtonLineLayout>
    </Container>
  );
}

MonitorPeriodDropdown.propTypes = {
  onValidatePeriod: PropTypes.func.isRequired,
  currentPeriod: PropTypes.string.isRequired,
  currentCustomDates: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
};
MonitorPeriodDropdown.defaultProps = {
  disabled: false,
  currentCustomDates: {},
};

function MonitorPeriodSelect({ disabled, onValidateFilters }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const currentPeriod = useSelector(periodFilterSelector);
  const currentCustomDates = useSelector(customPeriodDatesSelector);
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const onValidatePeriod = useCallback(
    (selectedPeriod, customDates) => {
      dispatch(setPeriod(selectedPeriod));
      if (selectedPeriod === 'custom') {
        const customDatesFormatted = {};
        if (customDates?.minDate) {
          customDatesFormatted.minDate = getTimezoneUnawareDateString(
            customDates?.minDate
          );
        }
        if (customDates?.maxDate) {
          customDatesFormatted.maxDate = getTimezoneUnawareDateString(
            customDates?.maxDate
          );
        }
        dispatch(setCustomDate(customDatesFormatted));
      }
      onValidateFilters()();
      toggleOpen();
    },
    [toggleOpen]
  );
  const selectedItem = DATE_PERIODS.find(
    ({ value }) => value === currentPeriod
  );
  return (
    <Popup
      open={isOpen}
      flowing
      trigger={
        <span style={{ margin: 'auto 0' }}>
          <PrimaryTabButton
            content={
              (selectedItem?.value === 'custom' && (
                <PeriodCaption customDates={currentCustomDates} />
              )) || (
                <Trans
                  render={capitalizedTranslation}
                  id={selectedItem?.textId || t({ id: "custom" })}
                />
              )
            }
            disabled={disabled}
            icon="chevron down"
            labelPosition="right"
            onClick={toggleOpen}
            opened={isOpen ? 'true' : null}
          />
        </span>
      }
      content={
        // Trash component when display is toggled, to empty the dropdown state between usages
        isOpen ? (
          <MonitorPeriodDropdown
            disabled={disabled}
            onValidatePeriod={onValidatePeriod}
            currentPeriod={currentPeriod}
            currentCustomDates={currentCustomDates}
          />
        ) : null
      }
      on="click"
      onClose={toggleOpen}
      onOpen={toggleOpen}
      position="bottom right"
      style={{ zIndex: 900, padding: 0 }}
    />
  );
}

MonitorPeriodSelect.propTypes = {
  onValidateFilters: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MonitorPeriodSelect.defaultProps = { disabled: false };

export default MonitorPeriodSelect;
