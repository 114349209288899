import { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Dropdown, Grid, Header, Icon } from 'semantic-ui-react';

import {
  deleteProductHierarchyGroup,
  fetchProductHierarchyGroups,
} from 'actions/entity_groups';
import { maybeFetchViewFacets } from 'actions/facet';
import withPolling from 'actions/withPolling';
import { entityLabelFormatter } from 'selectors/entities';

import moment from 'moment';

import ProductHierarchyGroupCreateModal from 'components/customer/home/ProductHierarchyGroupCreateModal';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import { ListHelpTooltip } from 'components/ui/HelpTooltip';
import Link, { RouterLinkV2 } from 'components/ui/Link';
import { MediumPaddedSegment } from 'components/ui/Segment';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';

import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

import ProductHierarchyGroupDeleteModal from './ProductHierarchyGroupDeleteModal';

function EmptyPage({ createGroup }) {
  return (
    <EmptyDataPage
      i18nHeaderText={t({ id: "product-group-list.no-created-group-yet" })}
      actionComponent={
        <div style={{ margin: 'auto' }}>
          <Trans>
            <Link base="true" onClick={createGroup}>
              Créer un premier groupe
            </Link>
            , ou retourner à{' '}
            <RouterLinkV2 base="true" to="/facets">
              la liste des analyses
            </RouterLinkV2>
          </Trans>
          .
        </div>
      }
    />
  );
}

EmptyPage.propTypes = {
  createGroup: PropTypes.func.isRequired,
};

class ProductHierarchyGroupListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productHierarchyGroupToDelete: undefined,
      productHierarchyGroupToUpdate: undefined,
      displayCreateModal: false,
    };
    this.renderGroupRow = this.renderGroupRow.bind(this);
    this.handleCloseModalOrClickOnConfirmDelete =
      this.handleCloseModalOrClickOnConfirmDelete.bind(this);
  }

  componentDidMount() {
    const { onMaybeFetchViewFacets } = this.props;
    // Make sure view facets are loaded for tooltip reference
    onMaybeFetchViewFacets();
  }

  handleCloseModalOrClickOnConfirmDelete() {
    this.setState({ productHierarchyGroupToDelete: undefined });
  }

  getViewFacetName = (viewFacet) => {
    const { viewFacets } = this.props;
    let name = '...';
    if (viewFacets) {
      const facet = viewFacets.find((item) => item.id === viewFacet.id);
      // eslint-disable-next-line prefer-destructuring
      if (facet) name = facet.name;
    }
    return { name };
  };

  getViewFacetNames = (relatedViewFacets) =>
    relatedViewFacets.map(this.getViewFacetName);

  renderGroupRow = ({
    id,
    related_view_facets,
    n_view_facets,
    items,
    name,
    create_date,
  }) => {
    const { productHierarchyGroups, hierarchyLabelFormatter } = this.props;
    const nHierarchies = items.length;
    return (
      <MediumPaddedSegment key={`product-hierarchy-group-${id}`}>
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={4}>
              <b style={{ overflowWrap: 'break-word' }}>{name}</b>
            </Grid.Column>
            <Grid.Column width={3}>
              <i>
                {`${t({ id: "created-on" })} ${moment(create_date).format(
                  styleVariables.dateFormat
                )}`}
              </i>
            </Grid.Column>
            <Grid.Column width={3} textAlign="right" verticalAlign="middle">
              <ListHelpTooltip
                mouseEnterDelay={100}
                mouseLeaveDelay={100}
                on="hover"
                position="right center"
                size="tiny"
                title={t({ id: "product(s)-service(s)" })}
                items={items}
                getItemText={(item) => hierarchyLabelFormatter(item.id)}
                triggerText={t`${nHierarchies} elements`}
              />
            </Grid.Column>
            <Grid.Column width={3} textAlign="right" verticalAlign="middle">
              {n_view_facets > 0 ? (
                <ListHelpTooltip
                  mouseEnterDelay={100}
                  mouseLeaveDelay={100}
                  on="hover"
                  position="right center"
                  size="tiny"
                  title={t({ id: "group-related-analysis" })}
                  items={related_view_facets}
                  getItemText={(facet) => this.getViewFacetName(facet).name}
                  triggerText={t`${n_view_facets} analyse(s)`}
                />
              ) : (
                <Trans id="0 analyse" />
              )}
            </Grid.Column>
            <Grid.Column width={3} textAlign="right">
              <Dropdown direction="left" icon="ellipsis vertical">
                <Dropdown.Menu>
                  <Dropdown.Item
                    content={t({ id: "modify" })}
                    icon={<Icon name="setting" />}
                    onClick={() => {
                      this.setState({
                        displayCreateModal: true,
                        productHierarchyGroupToUpdate:
                          productHierarchyGroups[id],
                      });
                    }}
                  />
                  <Dropdown.Item
                    content={t({ id: "delete" })}
                    icon={<Icon name="delete" color="red" />}
                    onClick={() =>
                      this.setState({
                        productHierarchyGroupToDelete:
                          productHierarchyGroups[id],
                      })
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MediumPaddedSegment>
    );
  };

  renderGroupList() {
    const { productHierarchyGroups, productHierarchyGroupsAreLoaded } =
      this.props;
    if (!productHierarchyGroupsAreLoaded) return null;

    const usedProductHierarchyGroups = [];
    const unusedProductHierarchyGroups = [];
    Object.values(productHierarchyGroups).forEach((group) => {
      if (group.n_view_facets) {
        usedProductHierarchyGroups.push(group);
      } else {
        unusedProductHierarchyGroups.push(group);
      }
    });
    return !Object.keys(productHierarchyGroups).length ? (
      <EmptyPage
        createGroup={() => this.setState({ displayCreateModal: true })}
      />
    ) : (
      <div style={{ overflowY: 'auto', flexGrow: 1 }}>
        {Object.values(usedProductHierarchyGroups).length ? (
          <div>
            {Object.values(usedProductHierarchyGroups).map(this.renderGroupRow)}
          </div>
        ) : null}
        {Object.values(unusedProductHierarchyGroups).length ? (
          <>
            <Header>
              <Trans id="unused-product-groups" />
            </Header>
            <div>
              {Object.values(unusedProductHierarchyGroups).map(
                this.renderGroupRow
              )}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  render() {
    const { onDeleteProductHierarchyGroup } = this.props;
    const {
      productHierarchyGroupToDelete,
      productHierarchyGroupToUpdate,
      displayCreateModal,
    } = this.state;
    const relatedViewFacetNames =
      (productHierarchyGroupToDelete?.related_view_facets &&
        this.getViewFacetNames(
          productHierarchyGroupToDelete.related_view_facets
        )) ||
      (productHierarchyGroupToUpdate?.related_view_facets &&
        this.getViewFacetNames(
          productHierarchyGroupToUpdate.related_view_facets
        )) ||
      undefined;
    return (
      <PageLayout simple padded>
        <ProductHierarchyGroupDeleteModal
          open={productHierarchyGroupToDelete !== undefined}
          productHierarchyGroup={productHierarchyGroupToDelete}
          handleCloseModalOrClickOnConfirmDelete={
            this.handleCloseModalOrClickOnConfirmDelete
          }
          deleteProductHierarchyGroup={onDeleteProductHierarchyGroup}
          relatedViewFacetNames={relatedViewFacetNames}
        />
        <ButtonLineLayout>
          <AnalyticsAwareButton
            gaCategory="V2"
            gaAction="Add Product Group"
            gaLabel="From Product Group List"
            inputComponent={ButtonAccent}
            type="button"
            onClick={() => this.setState({ displayCreateModal: true })}
            style={{ marginBottom: styleVariables.spaceMedium }}
            icon="plus"
            labelPosition="right"
            data-testid="bo-new-group-button"
            content={t({ id: "new-group" })}
          />
        </ButtonLineLayout>
        {this.renderGroupList()}
        <ProductHierarchyGroupCreateModal
          open={displayCreateModal}
          initialGroup={productHierarchyGroupToUpdate}
          relatedViewFacetNames={relatedViewFacetNames}
          onClose={() =>
            this.setState({
              displayCreateModal: false,
              productHierarchyGroupToUpdate: undefined,
            })
          }
        />
      </PageLayout>
    );
  }
}

ProductHierarchyGroupListPage.propTypes = {
  productHierarchyGroups: PropTypes.objectOf(commonPropTypes.entityGroup),
  viewFacets: PropTypes.arrayOf(commonPropTypes.viewFacet),
  onMaybeFetchViewFacets: PropTypes.func.isRequired,
  onDeleteProductHierarchyGroup: PropTypes.func.isRequired,
  hierarchyLabelFormatter: PropTypes.func.isRequired,
  productHierarchyGroupsAreLoaded: PropTypes.bool.isRequired,
};

ProductHierarchyGroupListPage.defaultProps = {
  productHierarchyGroups: undefined,
  viewFacets: undefined,
};

const ProductHierarchyGroupListPageWithFacetListPolling = withPolling(
  fetchProductHierarchyGroups,
  50000
)(ProductHierarchyGroupListPage);

export default connect(
  (state) => ({
    productHierarchyGroups: state.entities.productHierarchyGroups,
    productHierarchyGroupsAreLoaded: state.entities.loaded.includes(
      'productHierarchyGroups'
    ),
    viewFacets: state.facet.viewFacets,
    views: state.view.views,
    hierarchyLabelFormatter: (id) =>
      entityLabelFormatter(state.entities, 'productHierarchy', id),
  }),
  (dispatch) => ({
    onMaybeFetchViewFacets: () => dispatch(maybeFetchViewFacets()),
    onDeleteProductHierarchyGroup: (productHierarchyGroupId) =>
      dispatch(deleteProductHierarchyGroup(productHierarchyGroupId)),
  })
)(ProductHierarchyGroupListPageWithFacetListPolling);
